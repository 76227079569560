import $ from "jquery"
import React, { useEffect } from "react"
import bgVideo from "../../../static/2021videos/background-videos/BG_Welcome_Landing_v14_OP.mp4"
import { WheelIndicator } from "../../assets/js/wheel-indicator.js"
import { DepartmentCard } from "../DepartmentCard/DepartmentCard"
import { CreateSpan, CreateParagraph } from "../../utils/create.htmlElement"

export default function ({
  description,
  landingTitle,
  landingYear,
  mainTitle,
  subtitle,
  bannerCardSection,
  bannerArticleCardSection: articleSlides,
  backgroundImage,
  backgroundImageMobile,
  backgroundVideoUrl,
  verticalAnimation,
}) {
  useEffect(() => {
    if (typeof window !== undefined) {
      if (typeof window !== undefined) {
        const cardSlider = $("#thumb-slider")
        const prevBtn = $(".slick-prev");
        const nextBtn = $(".slick-next");

        cardSlider.on("click", nextBtn, function () {
          cardSequence()
        });

        cardSlider.on("click", prevBtn, function () {
          cardSequence()
        });

        function cardSequence() {
          $(".thumb-slide").each(function () {
            $(this).removeAttr("data-id")
          })

          $(".slick-current").attr("data-id", "1")
          $(".slick-current").next(".thumb-slide").attr("data-id", "2")
          $('.thumb-slide[data-id="2"]')
            .next(".thumb-slide")
            .attr("data-id", "3")
          $('.thumb-slide[data-id="3"]')
            .next(".thumb-slide")
            .attr("data-id", "4")
        }
        cardSlider.not(".slick-initialized").slick({
          dots: true,
          infinite: false,
          speed: 550,
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst: true,
          adaptiveHeight: true,
          variableWidth: true,
          arrows: true,
          prevArrow: '<button type="button" data-title="PREVIOUS ARTICLE" class="slick-prev"><svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" class="caret-left"><path d="M11 4 L6 9 11 14 12 13 8 9 12 5 Z"></path></svg></button>',
          nextArrow: '<button type="button" data-title="NEXT <br/> ARTICLE" class="slick-next"><svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" class="caret-right"><path d="M7 4 L12 9 7 14 6 13 10 9 6 5 Z"></path></svg></button>',
          swipeToSlide: true,
          cssEase: "ease",
          touchThreshold: 100,
          centerPadding: "0",
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2,
              },
            },
          ],
        })
      }
    }
  }, [])

  const { publicURL } = backgroundImage?.localFile || {}
  const { publicURL: mobilePublicUrl } = backgroundImageMobile?.localFile || {}
  const mappedArticleSlides = (articleSlides || []).map(article => {
    return {
      description: article.post.listDesciption,
      slug: article.slug,
      link: article.uri,
      name: article.title,
      isArticle: true,
      details_sections: {
        sections: [
          {
            backgroundImage: article?.post?.heroBackgroundImage,
            heroBackgroundImage: article?.post?.backgroundImage
          },
        ],
      },
    }
  })

  const slides = [...bannerCardSection, ...mappedArticleSlides]

  return (
    <section
      className="landing-section home-banner bg-media"
      style={{
        backgroundImage: `url(${publicURL})`,
      }}
    >
      <div
        className="bg-video"
        style={{
          backgroundImage: `url(${mobilePublicUrl})`,
        }}
      >
        <video
          className="video"
          width="100%"
          height="100vh"
          preload="auto"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={bgVideo} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>

        <video
          className="tablet-video"
          width="100%"
          height="100vh"
          preload="auto"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={bgVideo} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>

      <div className="container-inner">
        <div className="landing-copy">
          <div className="landing-title">
            <div className="label">{landingTitle}</div>
            <div className="year">{landingYear}</div>
          </div>
          <div className="landing-header">
            <h1 className="title">
              <CreateSpan className="lead" content={mainTitle} />
              <CreateSpan content={subtitle} />
            </h1>
            <CreateParagraph className="text" content={description} />
          </div>
        </div>

        <div className="card-slider general-slider" id="thumb-slider">
          {(slides || []).map((slide, index) => (
            <DepartmentCard
              key={index}
              {...slide}
              verticalAnimation={verticalAnimation}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
