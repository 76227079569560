import { graphql } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import gsap from "gsap"
import React, { useRef, useEffect } from "react"
import HomeBanner from "../../components/HomeBanner/HomeBanner"
import { Layout } from "../../components/Layout/Layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import Global_Constant from "../../constants/Global_Contants"

export default ({ data, id }) => {
  const stub = {
    backgroundImage: { localFile: {} },
  }
  const {
    site: {
      siteMetadata: { description },
    },
    page: {
      sections: { sections },
    },
  } = data
  const homeBanner = !!sections ? sections[0] : stub

  const { landingTitle, landingYear, subtitle, socialShareImage } = homeBanner

  const transitionCoverRef = useRef()
  const layoutContentsRef = useRef()
  const { publicURL } = socialShareImage?.localFile || {}

  const verticalAnimation = ({ length }, direction) => {
    const directionTo = direction === "up" ? "-100%" : "100%"
    const directionFrom = direction === "up" ? "100%" : "-100%"

    // convert ms to s for gsap
    const seconds = length

    return gsap
      .timeline()
      .set(transitionCoverRef.current, { y: directionFrom })
      .to(transitionCoverRef.current, {
        y: "0%",
        ease: "power1.easeInOut",
        duration: seconds / 2,
      })
      .set(layoutContentsRef.current, { opacity: 0 })
      .to(transitionCoverRef.current, {
        y: directionTo,
        ease: "power1.easeIn",
        duration: seconds / 2,
      })
  }

  useEffect(() => {
    if (
      typeof window !== undefined &&
      document.body.classList.contains("article-loaded")
    ) {
      document.body.classList.remove("article-loaded")
    }
  }, [])

  return (
    <>
      <GatsbySeo
        title={`${landingTitle}`}
        description={description && description.length ? `${description}` : `${Global_Constant.SEOdescription}`}
        canonical={process.env.WP_SITE_URL}
        openGraph={{
          url: `${process.env.WP_SITE_URL}`,
          title: `${subtitle}`,
          description: description && description.length ? `${description}` : `${Global_Constant.SEOdescription}`,
          site_name: ` HSS | ${landingTitle}`,
          type: "page",
          images: [{
            url: `${publicURL}`
          }]
        }}
      />
      <Layout
        verticalAnimation={verticalAnimation}
      >
        <main className="landing landing-general" ref={layoutContentsRef}>
          <HomeBanner {...homeBanner} verticalAnimation={verticalAnimation} />
        </main>
        <div className="cloneSlideWrap general">
          <div className="cloneCard cloneCard1"></div>
          <div className="cloneCard cloneCard2"></div>
          <div className="cloneCard cloneCard3"></div>
          <div className="cloneCard cloneCard4"></div>
        </div>
        <TransitionPortal>
          <div
            ref={transitionCoverRef}
            className="curtain-loader"
            style={{
              position: "fixed",
              background: "#1aafe5",
              top: 0,
              right: 0,
              width: "100%",
              height: "100vh",
              transform: "translateY(100%)",
            }}
          />
        </TransitionPortal>
      </Layout>
    </>
  )
}

export const query = graphql`
  query page($id: String!) {
    site {
      siteMetadata {
        description
        siteUrl
        title
      }
    }
    page: wpPage(id: { eq: $id }) {
      id
      slug
      sections {
        sections {
          ...HomeBanner
        }
      }
    }
  }
  fragment HomeBanner on WpPage_Sections_Sections_HomeBanner {
    backgroundVideoUrl
    description
    fieldGroupName
    landingTitle
    landingYear
    mainTitle
    subtitle
    backgroundImage {
      altText
      localFile {
        publicURL
      }
    }
    backgroundImageMobile {
      localFile {
        publicURL
      }
    }
    socialShareImage {
      localFile {
        publicURL
      }
    }
    bannerArticleCardSection {
      ... on WpPost {
        id
        uri
        title
        status
        slug
        post {
          listDesciption
          fieldGroupName
          backgroundImage {
            title
            localFile {
              publicURL
            }
          }
          heroBackgroundImage {
            title
            localFile {
              publicURL
            }
          }
          articleOrder
        }
      }
    }
    bannerCardSection {
      uri
      slug
      link
      name
      description
      details_sections {
        sections {
          ...CardDetailLight
        }
      }
    }
  }
  fragment CardDetailLight on WpCategory_DetailsSections_Sections_BannerSection {
    backgroundImage {
      localFile {
        publicURL
      }
    }
    heroBackgroundImage {
      localFile {
        publicURL
      }
    }
  }
`
